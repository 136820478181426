import React, { useEffect, useState } from "react";
import "../Pages/UserDashboard.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function UpdateBlogs() {
  const navigator = useNavigate();
  const [formData, setFormData] = useState({
    blogId: "",
    blogHeading: "",
    blogContent: "",
    blogImage: null,
  });
  const [errors, setErrors] = useState({});
  const { blogId } = useParams(); // Fix here
  const [blogImage, setBlogImage] = useState();

  useEffect(() => {
    fetchData(blogId);
  },[blogId]);

  const fetchData = async (blogId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/FetchBlogs.php/fetchblog",
        { blogId: blogId }
      );
      setBlogImage(response.data.BlogImage);
      setFormData({
        blogId: blogId,
        blogHeading: response.data.BlogHeading,
        blogContent: response.data.BlogContent,
      });
    } catch (error) {
      console.error("Error fetching blog data:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (event) => {
    setFormData({
      ...formData,
      blogImage: event.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("blogId", blogId);
    formDataToSend.append("blogHeading", formData.blogHeading);
    formDataToSend.append("blogContent", formData.blogContent);
    formDataToSend.append("blogImage", formData.blogImage);

    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/updateBlog.php/Blogs/save",
        formDataToSend
      );

      if (response.data.status === 0) {
        setErrors({ serverError: response.data.error });
        console.error("Error submitting form:", response.data);
      } else {
        navigator("/AdminDashboard/Blog");
        // console.log("Form submitted successfully:", response.data);
        fetchData();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <h2 className="heading p-3">Update Blog</h2>
        <div className="card">
          <form
            action=""
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            className="BlogForm customForm"
          >
            <div className="card-body">
              <input
                type="text"
                className="form-control"
                id="blogId"
                onChange={handleChange}
                name="blogId"
                value={formData.blogId}
                placeholder="Blog Id"
                required
              />
              <div className="form-group mb-3">
                <label htmlFor="blogHeading" className="form-label">
                  Blog Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="blogHeading"
                  onChange={handleChange}
                  name="blogHeading"
                  value={formData.blogHeading}
                  placeholder="Blog Title"
                  required
                />
              </div>
              <div className="d-flex justify-content-between flex-wrap">
                <div className="form-group mb-3" style={{ width: "85%" }}>
                  <label htmlFor="blogImage" className="form-label">
                    Blog Image
                  </label>
                  <input
                    type="file"
                    name="blogImage"
                    className="form-control"
                    onChange={handleImageChange}
                    id="blogImage"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <img
                    src={`https://practicoit.in/Server/WebContent/${blogImage}`}
                    alt=""
                    style={{ width: "100px" }}
                  />
                </div>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="blogContent" className="form-label">
                  Blog Content
                </label>
                <textarea
                  className="form-control"
                  name="blogContent"
                  onChange={handleChange}
                  id="blog-description"
                  rows="10"
                  value={formData.blogContent}
                  required
                ></textarea>
              </div>
            </div>
            {errors.serverError && (
              <div className="alert alert-danger mx-6 mb-4" role="alert">
                {errors.serverError}
              </div>
            )}
            <div className="mb-4 mx-7">
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdateBlogs;
