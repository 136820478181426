import React, { useState, useEffect, useCallback } from "react";
import "./AdminLogin.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function AdminLogin() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    username: "",
    password: "",
    serverError: "",
  });

  const handleLogout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("loginTime");
    navigate("/AdminLogin");
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const loginTime = localStorage.getItem("loginTime");

    const checkLoginStatus = () => {
      if (token && loginTime) {
        try {
          const timeElapsed = Date.now() - parseInt(loginTime);
          const hoursElapsed = timeElapsed / (1000 * 60 * 60);

          if (hoursElapsed < 24) {
            navigate("/");
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error("Error checking login status:", error);
          handleLogout();
        }
      }
    };

    checkLoginStatus();
  }, [handleLogout, navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(
          "https://practicoit.in/Server/Admin/LoginAdmin.php/LoginAdmin",
          formData
        );
        if (response.data.status === 1) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("loginTime", Date.now().toString());
          navigate("/");
          window.location.reload();
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            serverError: response.data.message,
          }));
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setErrors((prevErrors) => ({
          ...prevErrors,
          serverError: "Server error, please try again later.",
        }));
      }
    } else {
      console.log("Form submission failed due to validation errors.");
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      username: "",
      password: "",
      serverError: "",
    };

    if (formData.username.trim() === "") {
      newErrors.username = "Please enter your username.";
      valid = false;
    }

    if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  return (
    <div className="container login-container">
      <div className="row login-box">
        <div className="login-key">
          <i className="fa fa-key" aria-hidden="true"></i>
        </div>
        <div className="login-title">Admin Login</div>
        <div className="login-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username" className="form-control-label">
                USERNAME
              </label>
              <input
                type="text"
                id="username"
                className={`form-control ${errors.username && "is-invalid"}`}
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                required
                autoComplete="off"
              />
              {errors.username && (
                <div className="invalid-feedback">{errors.username}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="password" className="form-control-label">
                PASSWORD
              </label>
              <input
                type="password"
                id="password"
                className={`form-control ${errors.password && "is-invalid"}`}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                minLength="6"
                required
              />
              {errors.password && (
                <div className="invalid-feedback">{errors.password}</div>
              )}
            </div>
            {errors.serverError && (
              <div className="text-danger">{errors.serverError}</div>
            )}
            <div className="login-btn">
              <button type="submit" className="btn btn-outline-primary">
                LOGIN
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
