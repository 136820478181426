import React, { useEffect, useState } from "react";
import "../Pages/UserDashboard.css";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

function BatchDisc() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  // const [errors, setErrors] = useState({});
  const { batchid } = useParams();
  const [getName, setGetName] = useState("");

  useEffect(() => {
    fetchData();
  });

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchInterns.php/fetchIntern",
        { batchId: batchid }
      );
      console.log(batchid);
      console.log(response.data);
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error("Error fetching inbox data:", error);
    }
  };

  // fetch batch data
  useEffect(() => {
    fetchBatchData();
  });
  const fetchBatchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchBatch.php/fetchBatch",
        { batchId: batchid }
      );
      console.log(response.data);
      setGetName(response.data.BatchName);
    } catch (error) {
      console.error("Error fetching inbox data:", error);
    }
  };
  const handleFilterChange = (event) => {
    const { value } = event.target;
    setFilterValue(value);
    filterData(value);
  };

  const filterData = (value) => {
    const filtered = data.filter((item) => {
      const filterValueLower = value.toLowerCase().trim();
      const emailLower = item.Email ? item.Email.toLowerCase() : "";
      const nameLower =
        item.Firstname && item.Lastname
          ? (item.Firstname + " " + item.Lastname).toLowerCase()
          : "";
      const phoneString = item.Phone ? item.Phone.toString() : ""; // Convert to string if not already

      return (
        emailLower.includes(filterValueLower) ||
        nameLower.includes(filterValueLower) ||
        (phoneString && phoneString.includes(filterValueLower))
      );
    });
    setFilteredData(filtered);
  };

  return (
    <>
      <div className="container-fluid">
        <h2 className="heading p-3">{getName}</h2>
        <div className="card my-5 customForm">
          <div className="card-body">
            {" "}
            <input
              type="text"
              className="form-control col-6"
              placeholder="Filter by name, email, phone"
              value={filterValue}
              onChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="card mb-3" style={{ overflow: "hidden" }}>
          <div className="card-body previousUpdatesTable">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">InternId</th>
                  <th scope="col">Name</th>
                  <th scope="col">Plan</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone No.</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(filteredData) && filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.InternId}</td>
                      <td>
                        <Link
                          to={`/AdminDashboard/InternProfile/${item.InternId}`}
                        >
                          {item.Firstname + " " + item.Lastname}
                        </Link>
                      </td>
                      <td>{item.SelectedPlan}</td>
                      <td>
                        <a href={`mailto:${item.Email}`}>{item.Email}</a>
                      </td>
                      <td>{item.Phone}</td>
                      <td>{item.Status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default BatchDisc;
