import React, { useEffect, useState } from "react";
import "../Pages/UserDashboard.css";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

function AddInBatch() {
  const [data, setData] = useState([]);
  const { batchid } = useParams();
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [getName, setGetName] = useState("");

  useEffect(() => {
    fetchInternData();
  }, []);

  //   fetch intern data
  const fetchInternData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchInterns.php/fetchIntern"
      );
      //   console.log(response.data);
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error("Error fetching inbox data:", error);
    }
  };

  // fetch batch data
  useEffect(() => {
    fetchBatchData();
  });
  const fetchBatchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchBatch.php/fetchBatch",
        { batchId: batchid }
      );
      console.log(response.data);
      setGetName(response.data.BatchName);
    } catch (error) {
      console.error("Error fetching inbox data:", error);
    }
  };
  const handleFilterChange = (event) => {
    const { value } = event.target;
    setFilterValue(value);
    filterData(value);
  };

  const filterData = (value) => {
    const filtered = data.filter((item) => {
      const filterValueLower = value.toLowerCase().trim();
      const emailLower = item.Email ? item.Email.toLowerCase() : "";
      const nameLower =
        item.Firstname && item.Lastname
          ? (item.Firstname + " " + item.Lastname).toLowerCase()
          : "";
      const phoneString = item.Phone ? item.Phone.toString() : ""; // Convert to string if not already
      const Batches = item.BatchId ? item.BatchId.toString() : ""; // Convert to string if not already

      return (
        emailLower.includes(filterValueLower) ||
        nameLower.includes(filterValueLower) ||
        (phoneString && phoneString.includes(filterValueLower)) ||
        (Batches && Batches.includes(filterValueLower))
      );
    });
    setFilteredData(filtered);
  };

  // Add Intern to Batch
  const handleAddIntern = async (internId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/AddInBatch.php/addInBatch",
        { internId: internId, batchId: batchid }
      );
      console.log(internId);
      if (response.data.success) {
        fetchInternData();
      }
    } catch (error) {
      console.error("Error adding intern to batch:", error);
    }
  };

  return (
    <div className="container-fluid">
      <h2 className="heading p-3">Add In {getName}</h2>
      <div className="card my-5 customForm">
        <div className="card-body">
          {" "}
          <input
            type="text"
            className="form-control col-6"
            placeholder="Filter by name, email, phone or batch"
            value={filterValue}
            onChange={handleFilterChange}
          />
        </div>
        
      </div>
      <div className="card mb-3" style={{ overflow: "hidden" }}>
        <div className="card-body previousUpdatesTable">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">InternId</th>
                <th scope="col">Name</th>
                <th scope="col">Plan</th>
                <th scope="col">Email</th>
                <th scope="col">Phone No.</th>
                <th scope="col">Status</th>
                <th scope="col">Batch Id</th>
                <th scope="col">Add to Batch</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(filteredData) && filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.InternId}</td>
                    <td>
                      <Link
                        to={`/AdminDashboard/InternProfile/${item.InternId}`}
                      >
                        {item.Firstname + " " + item.Lastname}
                      </Link>
                    </td>
                    <td>{item.SelectedPlan}</td>
                    <td>
                      <a href={`mailto:${item.Email}`}>{item.Email}</a>
                    </td>
                    <td>{item.Phone}</td>
                    <td>{item.Status}</td>
                    <td>{item.BatchId ? item.BatchId : "Not Added"}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        disabled={item.BatchId === batchid ? "disabled" : ""}
                        onClick={() => handleAddIntern(item.InternId)}
                      >
                        {item.BatchId === batchid ? "Added" : "Add"}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AddInBatch;
