import React,{useCallback} from "react";
import { NavLink,useNavigate } from "react-router-dom";

function DashSidebar() {
  const navigator = useNavigate();
  // Function to toggle sidebar menu
  const handleMenuToggle = (e) => {
    e.preventDefault();
    document.getElementById("wrapper").classList.toggle("toggled");
  };


  const handleLogout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("loginTime");
    navigator("/");
    window.location.reload();
  }, [navigator]);

  return (
    <>
      <div id="sidebar-wrapper">
        <ul className="sidebar-nav " style={{ listStyle: "none" }}>
          <li>
            <NavLink to="/">
              <i className="fa-solid fa-home"></i>
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/AdminDashboard/Users">
            <i class="fa-solid fa-user"></i>
              <span>Users</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/AdminDashboard/Batch">
              <i className="fas fa-chart-line"></i>

              <span>Batches</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/AdminDashboard/Intern">
              <i className="fas fa-chart-line"></i>

              <span>Interns</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/AdminDashboard/Notification">
              <i className="fas fa-bell"></i>

              <span>Notification</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/AdminDashboard/Blog">
              <i className="fas fa-chalkboard"></i>
              <span>Blog</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/AdminDashboard/ClientTestimonial">
              <i className="fas fa-gem"></i>
              <span>Testimonials</span>
            </NavLink>
          </li>
          <li>
            <a href="" onClick={handleLogout}>
              <i className="fa-solid fa-right-from-bracket"></i>
              <span>Logout</span>
            </a>
          </li>
        </ul>
        <button
          className="menu-toggle sidebar-toggle bg-transparent p-0 "
          onClick={handleMenuToggle}
        >
          <i className="fa fa-lg fa-caret-right fs-4"></i>
        </button>
      </div>
    </>
  );
}

export default DashSidebar;
