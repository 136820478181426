import React, { useEffect, useState } from "react";
import "../Pages/UserDashboard.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function UpdateTestimonial() {
  const navigator = useNavigate();
  const [formData, setFormData] = useState({
    Id: "",
    clientName: "",
    profession: "",
    blogImage: null,
    comment: "",
  });
  const [errors, setErrors] = useState({});
  const { Id } = useParams(); // Fix here
  const [clientImage, setClientImage] = useState();

  useEffect(() => {
    fetchData(Id);
  }, [Id]);

  const fetchData = async (Id) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/fetchClientTestimonial.php/fetchClientTestimonial",
        { Id: Id }
      );
      console.log(response.data);
      setClientImage(response.data.clientImage);
      setFormData({
        clientName: response.data.clientName,
        profession: response.data.profession,
        comment: response.data.comment,
      });
    } catch (error) {
      console.error("Error fetching blog data:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (event) => {
    setFormData({
      ...formData,
      clientImage: event.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("Id", Id);
    formDataToSend.append("clientName", formData.clientName);
    formDataToSend.append("profession", formData.profession);
    formDataToSend.append("comment", formData.comment);
    formDataToSend.append("clientImage", formData.clientImage);

    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/UpdateClientTestimonial.php/UpdateClientTestimonial/save",
        formDataToSend
      );

      if (response.data.status === 0) {
        setErrors({ serverError: response.data.error });
        console.error("Error submitting form:", response.data);
      } else {
        // console.log(response.data);
        navigator("/AdminDashboard/ClientTestimonial");
        // console.log("Form submitted successfully:", response.data);
        fetchData();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <h2 className="heading p-3">Update Client Testimonial</h2>
        <div className="card">
          <form
            action=""
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            className="customForm"
          >
            <div className="card-body">
              <input
                type="text"
                className="form-control"
                id="Id"
                onChange={handleChange}
                name="Id"
                value={Id}
                placeholder="Id"
                required
                hidden
              />
              <div className="form-group mb-3">
                <label htmlFor="clientName" className="form-label">
                  Client Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="clientName"
                  onChange={handleChange}
                  name="clientName"
                  value={formData.clientName}
                  placeholder="Client Name"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="profession" className="form-label">
                  Profession
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="profession"
                  onChange={handleChange}
                  name="profession"
                  value={formData.profession}
                  placeholder="Profession"
                  required
                />
              </div>
              <div className="d-flex justify-content-between flex-wrap">
                <div className="form-group mb-3" style={{ width: "85%" }}>
                  <label htmlFor="CLient Image" className="form-label">
                    Client Image
                  </label>
                  <input
                    type="file"
                    name="blogImage"
                    className="form-control"
                    onChange={handleImageChange}
                    id="blogImage"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <img
                    src={`https://practicoit.in/Server/Admin/${clientImage}`}
                    alt=""
                    style={{ width: "100px" }}
                  />
                </div>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="comment" className="form-label">
                  Comment
                </label>
                <textarea
                  className="form-control"
                  name="comment"
                  onChange={handleChange}
                  id="comment"
                  rows="10"
                  value={formData.comment}
                  required
                ></textarea>
              </div>
            </div>
            {errors.serverError && (
              <div className="alert alert-danger mx-6 mb-4" role="alert">
                {errors.serverError}
              </div>
            )}
            <div className="mb-4 mx-7">
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdateTestimonial;
