import React, { useEffect, useState } from "react";
import "../Pages/UserDashboard.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function UpdateSession() {
  const navigator = useNavigate();
  const { sessionId } = useParams(); // Fix here
  const [formData, setFormData] = useState({
    sessionId:sessionId,
    sessionTopic: "",
    sessionDate: "",
    sessionLink: "",
    sessionTime:"",
  });
  const [errors, setErrors] = useState({});
  
  useEffect(() => {
    fetchData(sessionId);
  }, [sessionId]);

  const fetchData = async (sessionId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchSession.php/fetchSession",
        {sessionId:sessionId}
      );
      console.log(response.data)
    setFormData({
    sessionId:sessionId,
    sessionTopic: response.data.sessionTopic,
    sessionDate: response.data.sessionDate,
    sessionLink: response.data.sessionLink,
    sessionTime:response.data.sessionTime,
      });
    } catch (error) {
      console.error("Error fetching Session data:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/UpdateSession.php/UpdateSession/save",
        formData
      );

      console.log(formData)

      if (response.data.status === 0) {
        setErrors({ serverError: response.data.error });
        console.error("Error submitting form:", response.data);
      } else {
        navigator("/AdminDashboard/SessionSchedule");
        console.log("Form submitted successfully:", response.data);
        fetchData();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <h2 className="heading p-3">Update Blog</h2>
        <div className="card">
          <form
            action=""
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            className="BlogForm customForm"
          >
            <div className="card-body">
              
              <div className="form-group mb-3">
                <label htmlFor="sessionTopic" className="form-label">
                  Session Topic
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="sessionTopic"
                  onChange={handleChange}
                  name="sessionTopic"
                  value={formData.sessionTopic}
                  placeholder="Session Topic"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="sessionDate" className="form-label">
                   session Date
                </label>
                <input
                  type="Date"
                  className="form-control"
                  id="sessionDate"
                  onChange={handleChange}
                  name="sessionDate"
                  value={formData.sessionDate}
                  placeholder="Session Date"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="sessionLink" className="form-label">
                   session Link
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="sessionLink"
                  onChange={handleChange}
                  name="sessionLink"
                  value={formData.sessionLink}
                  placeholder="Session Link"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="sessionTime" className="form-label">
                   session Time
                </label>
                <input
                  type="Time"
                  className="form-control"
                  id="sessionTime"
                  onChange={handleChange}
                  name="sessionTime"
                  value={formData.sessionTime}
                  placeholder="Session Time"
                  required
                />
              </div>
                
            </div>
            {errors.serverError && (
              <div className="alert alert-danger mx-6 mb-4" role="alert">
                {errors.serverError}
              </div>
            )}
            <div className="mb-4 mx-7">
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdateSession;
