import React, { useState, useEffect } from "react";
import axios from "axios";

function AdminNotification() {
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedBatch, setSelectedBatch] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [batches, setBatches] = useState([]);

  // ######## Fetch Batch Data ########
  useEffect(() => {
    fetchBatchData();
  }, []);

  const fetchBatchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchBatch.php/fetchBatch"
      );
      setBatches(response.data);
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };

  // ######## Fetch User Data ########
  useEffect(() => {
    if (selectedBatch === "") {
      fetchUserData("");
    } else {
      fetchUserData(selectedBatch);
    }
  }, [selectedBatch]);

  const fetchUserData = async (batchId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchInterns.php/fetchIntern",
        { batchId: batchId }
      );
      if (batchId !== "") {
        setUsers(response.data);
      } else {
        setUsers("No data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // ######## Date and Time ########
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset(); // Get timezone offset in minutes
  currentDate.setMinutes(currentDate.getMinutes() - offset); // Adjust for timezone offset
  // Format the date and time as a MySQL-compatible string
  const formatedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");


  // ######## Send Notification ########
  const sendNotification = async (e) => {
    e.preventDefault();
    let recipients = [];
    if (selectedUser === "all") {
      // If "All" users are selected, include all users in the recipients list
      recipients = users.map((user) => user.Email);
    } else {
      // If a specific user is selected, include only that user in the recipients list
      recipients = [selectedUser];
    }

    try {
      // Send notification to each recipient
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/sendNotification.php/sendNotification",
        {
          batchId: selectedBatch,
          emails: recipients,
          subject: subject,
          message: message,
          date: formatedDate,
        }
      );
      console.log(recipients);
      console.log(response.data);
      // Optionally, you can clear the input fields after sending the notification
      setSelectedUser("");
      setSelectedBatch("");
      setSubject("");
      setMessage("");
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  return (
    <div className="container">
      <h2 className="mt-4 heading p-3">Send Notification (Admin)</h2>
      <form onSubmit={sendNotification} className="customForm">
        <div className="card-body">
          <div className="mb-3">
            <label htmlFor="batchSelect" className="form-label">
              Select Batch:
            </label>
            <select
              id="batchSelect"
              className="form-select"
              value={selectedBatch}
              onChange={(e) => setSelectedBatch(e.target.value)}
            >
              <option value="">Select Batch</option>
              {batches.map((batch) => (
                <option key={batch.Id} value={batch.BatchId}>
                  {batch.BatchName}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="userSelect" className="form-label">
              Select User:
            </label>
            <select
              id="userSelect"
              className="form-select"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="">Select User</option>
              {selectedBatch !== "" ? <option value="all">All</option> : ""}
              {Array.isArray(users) && users.length > 0
                ? users.map((user) => (
                    <option key={user.Id} value={user.Email}>
                      {user.Firstname + " " + user.Lastname}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="subject" className="form-label">
              Subject:
            </label>
            <input
              type="text"
              id="subject"
              className="form-control"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">
              Message:
            </label>
            <textarea
              id="message"
              className="form-control"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Send Notification
          </button>
        </div>
      </form>
    </div>
  );
}

export default AdminNotification;
