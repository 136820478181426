import React, { useEffect, useState } from "react";
import "../Pages/UserDashboard.css";
import axios from "axios";
import { Link } from "react-router-dom";

function Intern() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [internid, setInternid] = useState("");

  useEffect(() => {
    fetchData();
  }, []);
  
  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchInterns.php/fetchIntern"
      );
      setData(response.data);
      setFilteredData(response.data);
      const internId = response.data.map((item) => item.InternId);
      setInternid(internId.join());
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const handleFilterChange = (event) => {
    const { value } = event.target;
    setFilterValue(value);
    filterData(value);
  };

  const filterData = (value) => {
    const filtered = data.filter((item) => {
      const filterValueLower = value.toLowerCase().trim();
      const internid = item.InternId ? item.InternId.toLowerCase() : "";
      const emailLower = item.Email ? item.Email.toLowerCase() : "";
      const plan = item.SelectedPlan ? item.SelectedPlan.toLowerCase() : "";
      const selectionStatus = item.Status ? item.Status.toLowerCase() : "";
      const nameLower =
        item.Firstname && item.Lastname
          ? (item.Firstname + " " + item.Lastname).toLowerCase()
          : "";
          const phoneString = item.Phone ? item.Phone.toString() : "";
      const Batches = item.BatchId ? item.BatchId.toString() : "";
      
      return (
        internid.includes(filterValueLower) ||
        selectionStatus.includes(filterValueLower) ||
        emailLower.includes(filterValueLower) ||
        plan.includes(filterValueLower) ||
        nameLower.includes(filterValueLower) ||
        (phoneString && phoneString.includes(filterValueLower)) ||
        (Batches && Batches.includes(filterValueLower))
      );
    });
    setFilteredData(filtered);
  };

  
  // current Data and time
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset(); // Get timezone offset in minutes
  currentDate.setMinutes(currentDate.getMinutes() - offset); // Adjust for timezone offset
  
  // Format the date and time as a MySQL-compatible string
  const formatedDate = currentDate
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  
  // ###### Handle Update Status ######
  const handleUpdateStatus = async (status, InternId,formatedDate) => {
    try {
      const response=await axios.post(
        "https://practicoit.in/Server/Admin/updateInternStatus.php",
        { status,formatedDate,InternId }
      );
      fetchData(); 
      console.log(response.data,formatedDate);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  
  useEffect(() => {
    fetchPaymentDetails(internid);
    }, [internid]);
  // Fetch Paymentdetails
  const fetchPaymentDetails = async (InternId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchPaymentDetails.php",
        { InternId }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  
  return (
    <>
      <div className="container-fluid">
        <h2 className="heading p-3"> All Interns</h2>
        <div className="card my-5 customForm">
          <div className="card-body">
            <input
              type="text"
              className="form-control col-6"
              placeholder="Filter by name, email, phone, plan, selected status, intern id  or batch id"
              value={filterValue}
              onChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="card mb-3" style={{ overflow: "hidden" }}>
          <div className="card-body previousUpdatesTable">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">InternId</th>
                  <th scope="col">Name</th>
                  <th scope="col">Plan</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone No.</th>
                  <th scope="col">Selection Status</th>
                  <th scope="col">BatchId</th>
                  <th scope="col">Select / Reject (Interns)</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(filteredData) && filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.InternId}</td>
                      <td>
                        <Link
                          to={`/AdminDashboard/InternProfile/${item.InternId}`}
                        >
                          {item.Firstname + " " + item.Lastname}
                        </Link>
                      </td>
                      <td>{item.SelectedPlan}</td>
                      <td>
                        <a href={`mailto:${item.Email}`}>{item.Email}</a>
                      </td>
                      <td>{item.Phone}</td>
                      <td>{item.Status}</td>
                      <td>{item.BatchId === "" ? "N/A" : item.BatchId}</td>
                      <td>
                        <button
                          className="btn m-1"
                          onClick={() => handleUpdateStatus("Selected", item.InternId,formatedDate)}
                        >
                          <i className="fas fa-check"></i>{" "}
                        </button>
                        <button
                          className="btn reject m-1"
                          onClick={() => handleUpdateStatus("Rejected", item.InternId,formatedDate)}
                        >
                          <i className="fas fa-times"></i>{" "}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Intern;
