import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./InternProfile.css";

function InternProfile() {
  const { profileId } = useParams(); // Retrieve internId from URL parameter
  const [internData, setInternData] = useState(null);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState(null);

  // ####### Fetching Intern Profile ######
  useEffect(() => {
    fetchData();
  });

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchInterns.php",
        { internId: profileId }
      );
      const profileData = response.data;
      setInternData(profileData);
      setError(null);
    } catch (error) {
      console.error("Error fetching intern data:", error);
      setError("Error fetching intern data");
      setInternData(null);
    }
  };


  // ####### Fetching Project Status ######
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchprojectData(profileId);
  }, [profileId]);

  const fetchprojectData = async (InternId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/UserDashboard/getStatusData.php",
        { InternId }
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };


  // ######### Enlarge Image ########
  const [enlargedImageSrc, setEnlargedImageSrc] = useState(null);
  const [showEnlargedImage, setShowEnlargedImage] = useState(false);

  const enlargeImage = (clickedImgSrc) => {
    setEnlargedImageSrc(clickedImgSrc);
    setShowEnlargedImage(true);
  };
  const closeEnlargedImage = () => {
    setShowEnlargedImage(false);
  };


  // ######### Assign Projects ########

  // ####### Date and Time ######
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset(); // Get timezone offset in minutes
  currentDate.setMinutes(currentDate.getMinutes() - offset); // Adjust for timezone offset
  // Format the date and time as a MySQL-compatible string
  const formatedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");


  const [formData, setFormData] = useState({
    profileId: "",
    projectName: "",
    projectDescription: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
      profileId: profileId,
      date: formatedDate,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/insertInternsProject.php/insertInternsProject/save",
        formData
      );

      if (response.data.status === 0) {
        setErrors({ serverError: response.data.error });
      } else {
        setErrors(null);
        setFormData({
          profileId: "",
          projectName: "",
          projectDescription: "",
        });
        fetchProject(profileId);
        console.log("Form submitted successfully:", response.data);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // ####### Fetching Project Details ######
  const [projectDetail, setProjectDetail] = useState("");
  const [projectDescription, setProjectDescription] = useState("");

  useEffect(() => {
    fetchProject(profileId);
    fetchPaymentDetails(profileId);
  }, [profileId]);

  const fetchProject = async (InternId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/UserDashboard/projectData.php/projectData",
        { InternId }
      );
      console.log(response.data);
      setProjectDetail(response.data.projects[0]);
      setProjectDescription(
        response.data.projects[0].ProjectDescription.replaceAll(
          "\\n",
          "<br>"
        ).replaceAll("\\", "")
      );
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const [paymentId, setPaymentId] = useState();
  
  const fetchPaymentDetails = async (InternId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchPaymentDetails.php",
        { InternId }
      );
          setPaymentId(response.data.PaymentId);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  return (
    <>
      <div className="container-fluid">
        <h2 className="heading p-3">Intern Profile</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        {internData && (
          <div className="card my-5 profile-container">
            <div className="card-body d-flex flex-wrap" >
              {/* Display intern data here */}
              <div className="profileData">ID: {internData.InternId}</div>
              <div className="profileData">
                Name: {internData.Firstname} {internData.Lastname}
              </div>
              <div className="profileData">Gender: {internData.Gender}</div>
              <div className="profileData">
                Internship Done Before: {internData.InternshipDoneBefore}
              </div>
              <div className="profileData">
                College Course: {internData.Course}
              </div>
              <div className="profileData">Email: {internData.Email}</div>
              <div className="profileData">Phone: {internData.Phone}</div>
              <div className="profileData">
                College Name: {internData.CollegeName}
              </div>
              <div className="profileData">Plan: {internData.SelectedPlan}</div>
              <div className="profileData">Status: {internData.Status}</div>
              <div className="profileData">Batch: {internData.BatchId}</div>
              <div className="profileData">
                Applied Date: {internData.appliedAt}
              </div>
              <div className="profileData">
                Selected Date: {internData.selectedat}
              </div>
              {paymentId?
              <div className="profileData">
                Payment Id: {paymentId}
              </div>
              :null
              }
            </div>
          </div>
        )}

        {/* Assign Projects */}

        <h2 className="heading p-3">Assign Projects</h2>
        <div className="card customForm">
          <form
            action=""
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="row m-5">
              <div className="form-group mb-3 ">
                <input
                  type="hidden"
                  className="form-control"
                  id="profileId"
                  name="profileId"
                  value={profileId}
                  placeholder="Profile Id"
                  readOnly
                />
              </div>

              <div className="form-group mb-3">
                <label htmlFor="project-title" className="form-label">
                  Project Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="projectName"
                  onChange={handleChange}
                  name="projectName"
                  placeholder="Project Name"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="project-title" className="form-label">
                  Project Description
                </label>
                <textarea
                  className="form-control"
                  id="projectDescription"
                  onChange={handleChange}
                  name="projectDescription"
                  placeholder="Project Discription"
                  required
                />
              </div>
            </div>
            {errors && errors.serverError && (
              <div className="alert alert-danger mx-6 mb-4" role="alert">
                {errors.serverError && <p>{errors.serverError}</p>}
              </div>
            )}

            <div className="mb-4 mx-7">
              <button type="submit" className="btn btn-primary">
                Create
              </button>
            </div>
          </form>
        </div>
        <div className="card mt-3">
          <div className="card-body">
            <h3 className="heading p-0 bg-transparent">Project Detail</h3>
            {projectDetail && (
              <div>
                <p>Project Name: {projectDetail.ProjectName}</p>
                <p>
                  Project Description:
                  <div
                    dangerouslySetInnerHTML={{ __html: projectDescription }}
                  ></div>
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Fetch Project Status */}
        <h2 className="heading p-3"> Project Status </h2>
        <div className="card mb-3" style={{ overflow: "hidden" }}>
          <div className="card-body previousUpdatesTable">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="fs-6">
                    Date & Time
                  </th>
                  <th scope="col" className="fs-6">
                    Project Title
                  </th>
                  <th scope="col" className="fs-6">
                    Project Description
                  </th>
                  <th scope="col" className="fs-6">
                    Project Image
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.created_at}</td>
                      <td>{item.projectTitle}</td>
                      <td>{item.projectDescription}</td>
                      <td>
                        <div className="smallImage-container">
                          <img
                            src={`https://practicoit.in/Server/UserDashboard/${item.projectImage}`}
                            className="thumbnail"
                            alt={`Thumbnail ${item.projectTitle}`}
                            onClick={() =>
                              enlargeImage(
                                `https://practicoit.in/Server/UserDashboard/${item.projectImage}`
                              )
                            }
                            style={{
                              cursor: "pointer",
                              transition: "transform 0.2s  linear",
                              width: "100px",
                              height: "auto",
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Enlarged image container */}
        {showEnlargedImage && (
          <div className="enlargedImageContainer" onClick={closeEnlargedImage}>
            <div className="enlargeInnerContainer">
              <div className="enlarged-image">
                <img
                  src={enlargedImageSrc}
                  id="enlarged-img"
                  alt="Enlarged Thumbnail"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default InternProfile;
