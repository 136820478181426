// import React, { useEffect, useState } from "react";

const ErrorBoundary = ({ children }) => {
  // const [hasError, setHasError] = useState(false);

  // useEffect(() => {
  //   handleError(null, null);
  // })
  // // const handleError = (error, errorInfo) => {
  // //   // Log the error to an error reporting service
  // //   console.error(error, errorInfo);
  // //   // Update state so the next render will show the fallback UI
  // //   setHasError(true);
  // // };

  // if (hasError) {
  //   return <h1>Something went wrong.</h1>;
  // }

  return children;
};

export default ErrorBoundary;
