import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import DashNavbar from "./dashboardComponent/DashNavbar";
import "./Pages/UserDashboard.css";
import "./App.css";
import DashSidebar from "./dashboardComponent/DashSidebar";
import Loader from "./dashboardComponent/Loader";
import AdminLogin from "./dashboardComponent/AdminLogin";
import {jwtDecode} from "jwt-decode"; // Correct import for jwt-decode

function App() {
  const navigate = useNavigate(); // Add useNavigate for navigation
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    // Simulate an async operation to display loader
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.userId);
      } catch (error) {
        console.error("Invalid token", error);
        // Handle invalid token case, maybe log out the user
        localStorage.removeItem("token");
        navigate("/AdminLogin");
      }
    } else {
      navigate("/AdminLogin"); // Navigate to login if no token
    }
  }, [navigate]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : !userId ? (
        <AdminLogin />
      ) : (
        <div id="Dashboard-Main">
          <DashNavbar />
          <div id="wrapper">
            <DashSidebar />
            {/* Main Content */}
            <div id="page-content-wrapper">
              <div className="App">
                <main>
                  <Outlet />
                </main>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
