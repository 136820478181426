import React, { useEffect, useState } from "react";
import "../Pages/UserDashboard.css";
import axios from "axios";

function Users() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    fetchData();
  }, []);
  
  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/Users.php/Users"
      );
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const handleFilterChange = (event) => {
    const { value } = event.target;
    setFilterValue(value);
    filterData(value);
  };

  const filterData = (value) => {
    const filtered = data.filter((item) => {
      const filterValueLower = value.toLowerCase().trim();
      const username = item.username ? item.username.toLowerCase() : "";
      const emailLower = item.email ? item.email.toLowerCase() : "";
      
      return (
        username.includes(filterValueLower) ||
        emailLower.includes(filterValueLower) 
      );
    });
    setFilteredData(filtered);
  };

  
  return (
    <>
      <div className="container-fluid">
        <h2 className="heading p-3"> All SignUp Users</h2>
        <div className="card my-5 customForm">
          <div className="card-body">
            <input
              type="text"
              className="form-control col-6"
              placeholder="Filter by name, email, phone, plan, selected status, intern id  or batch id"
              value={filterValue}
              onChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="card mb-3" style={{ overflow: "hidden" }}>
          <div className="card-body previousUpdatesTable">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">SNO.</th>
                  <th scope="col">Username</th>
                  <th scope="col">Email</th>
                  <th scope="col">Joining Date</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(filteredData) && filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td>
                          {item.username}
                      </td>
                      <td>
                        <a href={`mailto:${item.email}`}>{item.email}</a>
                      </td>
                      <td>{item.created_at}</td>
                      </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Users;