import React, { useEffect, useState } from "react";
import "../Pages/UserDashboard.css";
import axios from "axios";
import { Link } from "react-router-dom";

function SessionSchedule() {
  const [formData, setFormData] = useState({
    sessionTopic: "",
    sessionDate: "",
  });
  const [errors, setErrors] = useState({});
  const [selectedBatch, setSelectedBatch] = useState("");
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    fetchBatchData();
    fetchData();
  }, []);

  const fetchBatchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchBatch.php/fetchBatch"
      );
      setBatches(response.data);
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchSession.php/fetchSession"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching session data:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!formData.sessionTopic || !formData.sessionDate || !selectedBatch) {
        setErrors({ serverError: 'Please fill out all required fields.' });
        return;
      }
  
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/scheduleSession.php/scheduleSession/save",
        { ...formData, selectedBatch }
      );
  
      if (response.data.status === 0) {
        setErrors({ serverError: response.data.error });
        console.log({ serverError: response.data.data })
      } else {
        setFormData({
          sessionTopic: "",
          sessionDate: "",
          
        });
        fetchData();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <h2 className="heading p-3"> Create New Session </h2>
      <div className="card">
        <form
          action=""
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="BlogForm customForm"
        >
          <div className="card-body">
            <div className="form-group mb-3">
              <label htmlFor="sessionTopic" className="form-label">
                Session Topic
              </label>
              <input
                type="text"
                className="form-control"
                id="sessionTopic"
                onChange={handleChange}
                name="sessionTopic"
                value={formData.sessionTopic}
                placeholder="Session Topic"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="sessionDate" className="form-label">
                Session Date
              </label>
              <input
                type="date"
                className="form-control"
                id="sessionDate"
                onChange={handleChange}
                name="sessionDate"
                value={formData.sessionDate}
                placeholder="Session Date"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="batchSelect" className="form-label">
                Select Batch:
              </label>
              <select
                id="batchSelect"
                className="form-select"
                value={selectedBatch}
                onChange={(e) => setSelectedBatch(e.target.value)}
              >
                <option value="">Select Batch</option>
                {batches.map((batch) => (
                  <option key={batch.Id} value={batch.BatchId}>
                    {batch.BatchName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {errors.serverError && (
            <div className="alert alert-danger mx-6 mb-4" role="alert">
              {errors.serverError}
            </div>
          )}
          <div className="mb-4 mx-7">
            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>

      <h2 className="heading p-3"> Previous Updates </h2>
      <div className="card mb-3" style={{ overflow: "hidden" }}>
        <div className="card-body previousUpdatesTable">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Batch</th>
                <th scope="col">Session Topic</th>
                <th scope="col">Session Date</th>
                <th scope="col">Session Link</th>
                <th scope="col">Session Time</th>
                <th scope="col">Update</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(data) && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.selectedBatch}</td>
                    <td>{item.sessionTopic}</td>
                    <td>{item.sessionDate}</td>
                    <td>{item.sessionLink}</td>
                    <td>{item.sessionTime}</td>
                    <td>
                      <Link to={`/AdminDashboard/UpdateSession/${item.Id}`}>
                        <button className="btn btn-primary">
                          <i className="fa-solid fa-pen-to-square"></i> Update
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SessionSchedule;
