import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/mainlogo.png";

function DashNavbar() {
  const handleMenuToggle = (e) => {
    e.preventDefault();
    document.getElementById("wrapper").classList.toggle("toggled");
  };
  return (
    <>
      {" "}
      <nav className="navbar justify-content-between navbar-expand-lg navbar-dark">
        <div className="container-fluid justify-content-start px-0">
          <button
            className="menu-toggle custom-toggler collapsed btn navbar-toggler me-3"
            onClick={handleMenuToggle}
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <NavLink to="/" className="navbar-logo ">
            <img src={logo} alt="Logo" className="logo" />
          </NavLink>
        </div>
        <div className="user-details align-items-center justify-content-end w-100">
          {/* User login */}
          <div className="btn-group dropstart user-login">
            <span className="nav-link text-capitalize px-1 text-color-Exdark">
              Welcome, Admin
            </span>
          </div>
        </div>
      </nav>
    </>
  );
}

export default DashNavbar;
