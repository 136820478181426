import React, { useEffect, useState } from "react";
import "../Pages/UserDashboard.css";
import { Link } from "react-router-dom";
import axios from "axios";

function Batch() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [errors, setErrors] = useState({});
  const [batchId, setBatchId] = useState("");
  const [formData, setFormData] = useState({
    batchId: "",
    batchName: "",
    batchType: "Select Batch Type",
  });

  // ####### Fetching Batches Data ######

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchBatch.php/fetchBatch"
      );
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // ####### Generate Batch Id ######
  useEffect(() => {
    generateBatchId();
  });

  const generateBatchId = () => {
    let maxId = -0;
    if (data.length > 0) {
      data.forEach((item) => {
        if (item.Id > maxId) {
          maxId = item.Id;
        }
      });
    }
    var id = maxId + 1;
    var currentDate = new Date();
    var day = currentDate.getDate();
    var month = currentDate.getMonth() + 1; // Months are zero-indexed, so we add 1
    var year = currentDate.getFullYear() % 100;
    year = year < 10 ? "0" + year : year.toString();
    const generatedBatchId = year + "" + month + "" + day + "" + id;
    setBatchId(generatedBatchId);
  };


  // ####### Generate Date ######
const currentDate = new Date();
const offset = currentDate.getTimezoneOffset(); // Get timezone offset in minutes
currentDate.setMinutes(currentDate.getMinutes() - offset); // Adjust for timezone offset

// Format the date and time as a MySQL-compatible string
const formatedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");


  // ######### Handle Form Data ############
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
      batchId: batchId,
      date: formatedDate,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/insertBatch.php/Batch/save",
        formData
      );
      console.log(formData);
      if (response.data.status === 0) {
        setErrors({ serverError: response.data.error });
      } else {
        setFormData({
          batchName: "",
          batchType: "Select Batch Type",
        });
        fetchData();
        console.log("Form submitted successfully:", response.data);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // ####### Filtering Batches #######

  const handleFilterChange = (event) => {
    const { value } = event.target;
    setFilterValue(value);
    filterData(value);
  };

  const filterData = (value) => {
    const filtered = data.filter((item) => {
      const filterValueLower = value.toLowerCase().trim();
      const batchIdLower = item.BatchId ? item.BatchId.toLowerCase() : "";
      const batchNameLower = item.BatchName ? item.BatchName.toLowerCase() : "";

      return (
        batchIdLower.includes(filterValueLower) ||
        batchNameLower.includes(filterValueLower)
      );
    });
    setFilteredData(filtered);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="container-fluid">
          <div>
            <h2 className="heading p-3"> Submit Project Status </h2>
            <div className="card customForm">
              <form
                action=""
                autoComplete="off"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <div className="row m-5">
                  <div className="form-group mb-3 col-6">
                    <label htmlFor="project-title" className="form-label">
                      Batch Id
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="batchId"
                      name="batchId"
                      value={batchId}
                      placeholder="Batch Id"
                      readOnly
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label htmlFor="project-title" className="form-label">
                      Batch Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="batchName"
                      onChange={handleChange}
                      name="batchName"
                      placeholder="Batch Name"
                      required
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label htmlFor="project-title" className="form-label">
                      Batch Type
                    </label>
                    <select
                      className="form-select"
                      name="batchType"
                      value={formData.batchType}
                      onChange={handleChange}
                    >
                      <option value="Select Batch Type">
                        Select Batch Type
                      </option>
                      <option value="Free">Free</option>
                      <option value="Paid">Paid</option>
                    </select>
                  </div>
                </div>
                {errors.serverError && (
                  <div className="alert alert-danger mx-6 mb-4" role="alert">
                    {errors.serverError && <p>{errors.serverError}</p>}
                  </div>
                )}
                <div className="mb-4 mx-7">
                  <button type="submit" className="btn btn-primary">
                    Create
                  </button>
                </div>
              </form>
            </div>
            {/* Previous Updates Section */}
            <h2 className="heading p-3"> Batches </h2>
            <div className="card mb-3" style={{ overflow: "hidden" }}>
              <div className="card customForm">
                <div className="card-body">
                  {" "}
                  <input
                    type="text"
                    className="form-control col-6"
                    placeholder="Filter by batch id or batch name"
                    value={filterValue}
                    onChange={handleFilterChange}
                  />
                </div>
              </div>
              <div className="card-body previousUpdatesTable">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Batch Id</th>
                      <th scope="col">Batch Name</th>
                      <th scope="col">Date & Time</th>
                      <th scope="col">Batch Type</th>
                      <th scope="col">Add Interns</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(filteredData) && filteredData.length > 0 ? (
                      filteredData.map((item, index) => (
                        <tr key={index}>
                          <td>{item.BatchId}</td>
                          <td>
                            <Link
                              to={`/AdminDashboard/BatchDisc/${item.BatchId}`}
                            >
                              {item.BatchName}
                            </Link>
                          </td>
                          <td>{item.created_at}</td>
                          <td>{item.BatchType}</td>
                          <td>
                            <Link
                              to={`/AdminDashboard/AddInBatch/${item.BatchId}`}
                            >
                              <button className="btn btn-primary">Add</button>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Batch;
