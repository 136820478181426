import React, { useEffect, useState } from "react";
import "../Pages/UserDashboard.css";
import axios from "axios";
import { Link } from "react-router-dom";

function ClientTestimonial() {
  const [formData, setFormData] = useState({
    clientName: "",
    profession: "",
    clientImage: null,
    comment: "",
  });
  const [errors, setErrors] = useState({});
  const [showEnlargedImage, setShowEnlargedImage] = useState(false);
  const [enlargedImageSrc, setEnlargedImageSrc] = useState(null);

  // ####### Fetching Intern Profile ######
  useEffect(() => {
    fetchData();
  }, []);

  const [data, setData] = React.useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/fetchClientTestimonial.php/fetchClientTestimonial"
      );
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching inbox data:", error);
    }
  };

  // ####### Handle Change ######
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ###### Handle Image Change ######
  const handleImageChange = (event) => {
    setFormData({
      ...formData,
      clientImage: event.target.files[0],
    });
  };


  // ######## Date and Time ##########
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset(); // Get timezone offset in minutes
  currentDate.setMinutes(currentDate.getMinutes() - offset); // Adjust for timezone offset
  // Format the date and time as a MySQL-compatible string
  const formatedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");

  // ####### Handle Form Sumbition ########
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("clientName", formData.clientName);
    formDataToSend.append("profession", formData.profession);
    formDataToSend.append("clientImage", formData.clientImage);
    formDataToSend.append("comment", formData.comment);
    formDataToSend.append("date", formatedDate);

    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/AddClientTestimonial.php/clientTestimonial/save",
        formDataToSend
      );

      console.log(formData);
      if (response.data.status === 0) {
        setErrors({ serverError: response.data.error });
      } else {
        setFormData({
          blogHeading: "",
          blogContent: "",
          blogImage: null,
        });
        console.log("Form submitted successfully:", response.data);
        fetchData(); // Reload blog data after submission
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const enlargeImage = (clickedImgSrc) => {
    setEnlargedImageSrc(clickedImgSrc);
    setShowEnlargedImage(true);
  };

  const closeEnlargedImage = () => {
    setShowEnlargedImage(false);
  };

  return (
    <>
      <div className="container-fluid">
        <h2 className="heading p-3"> Client Testimonial </h2>
        <div className="card">
          <form
            action=""
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            className="BlogForm customForm"
          >
            <div className="card-body">
              <div className="form-group mb-3">
                <label htmlFor="clientName" className="form-label">
                  Client Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="clientName"
                  onChange={handleChange}
                  name="clientName"
                  value={formData.blogHeading}
                  placeholder="Client Name"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="clientImage" className="form-label">
                  Client Image
                </label>
                <input
                  type="file"
                  name="clientImage"
                  className="form-control"
                  onChange={handleImageChange}
                  id="clientImage"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="profession" className="form-label">
                  Profession
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="profession"
                  onChange={handleChange}
                  name="profession"
                  value={formData.blogHeading}
                  placeholder="Profession"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="comment" className="form-label">
                  Comment
                </label>
                <textarea
                  className="form-control"
                  name="comment"
                  onChange={handleChange}
                  id="comment"
                  rows="10"
                  value={formData.blogContent}
                  required
                ></textarea>
              </div>
            </div>
            {errors.serverError && (
              <div className="alert alert-danger mx-6 mb-4" role="alert">
                {errors.serverError}
              </div>
            )}
            <div className="mb-4 mx-7">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>

        <h2 className="heading p-3"> Previous Updates </h2>
        <div className="card mb-3" style={{ overflow: "hidden" }}>
          <div className="card-body previousUpdatesTable">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Client Name</th>
                  <th scope="col">Profession</th>
                  <th scope="col">Client Image</th>
                  <th scope="col">Comment</th>
                  <th scope="col">Update</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) && data.length > 0
                  ? data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.clientName}</td>
                        <td>{item.profession}</td>
                        <td>
                          {item.comment
                            ? item.comment.slice(0, 100) + "..."
                            : "N/A"}
                        </td>
                        <td>
                          <div className="smallImage-container">
                            <img
                              src={`https://practicoit.in/Server/Admin/${item.clientImage}`}
                              className="thumbnail"
                              alt={`${item.BlogHeading}`}
                              onClick={() =>
                                enlargeImage(
                                  `https://practicoit.in/Server/Admin/${item.clientImage}`
                                )
                              }
                              style={{
                                cursor: "pointer",
                                transition: "transform 0.2s linear",
                                width: "100px",
                                height: "auto",
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <Link
                            to={`/AdminDashboard/UpdateTestimonial/${item.Id}`}
                          >
                            <button className="btn btn-primary">
                              <i className="fa-solid fa-pen-to-square"></i>
                              Update
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))
                  : <tr>
                  <td colSpan="5">No data available</td>
                </tr>}
              </tbody>
            </table>
          </div>
        </div>

        {showEnlargedImage && (
          <div className="enlargedImageContainer" onClick={closeEnlargedImage}>
            <div className="enlargeInnerContainer">
              <div className="enlarged-image">
                <img src={enlargedImageSrc} id="enlarged-img" alt="Enlarged" />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ClientTestimonial;
