import React, { useEffect, useState } from "react";
import axios from "axios";
import "./UserDashboard.css";
import { Link } from "react-router-dom";

function AdminDashboard() {
  const [data, setData] = useState("");
  

  // Fetch sessions
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchSession.php/fetchSession"
      );
      setData(response.data);
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching session data:", error);
    }
  };

  return (
    <>
      {/* Main Content */}
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div className="container-fluid">
            {/* Cards */}
            <div className="row py-3">
              <div className="col col-md-4 my-5">
                <div className="card introCard">
                  <div className="card-body d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <h2 className="text-color-lg">Intern Plans</h2>
                      <h3 className="text-color-light">
                        <span>Paid-20</span>
                        <span> , </span>
                        <span>Free-20</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-md-4 my-5 ">
                <div className="card introCard">
                  <div className="card-body d-flex justify-content-between align-items-center">
                    <div>
                      <h2 className="text-color-lg">Active Batches</h2>
                      <h2 className="text-color-light">50</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-md-4 my-5 ">
                <div className="card introCard">
                  <div className="card-body d-flex justify-content-between align-items-center">
                    <div>
                      <h2 className="text-color-lg">Active Interns</h2>
                      <h2 className="text-color-light">30</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Session Schedule */}

            <div className="session-schedule">
              <div className="heading py-3 d-flex justify-content-between">
                <h2 className="text-color-lg">Session Schedule</h2>
                <Link to="/AdminDashboard/SessionSchedule">
                <button className="btn">Schedule Sessons</button>
                </Link>
                
              </div>
              <div className="container Charged">
                <div className="row">
                {Array.isArray(data) && data.length > 0 ? (
                data.map((item, index) => (
                
                  <div className="card" key={index}>
                    <div className="card-body d-flex flex-column justify-content-between">
                      <h4 className="card-title my-1 d-flex justify-content-between"><span>{item.sessionTopic}</span> <span title="Batch Id">{item.selectedBatch}</span>  </h4>
                      <p className="d-flex justify-content-between">
                      <span>{item.sessionDate}</span>
                      <span>{item.sessionTime}</span>
                      </p>
                    </div>
                  </div>
                  ))):""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
