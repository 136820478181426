import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Blog from "./dashboardComponent/Blog.js";
import AdminDashboard from "./Pages/AdminDashboard.js";
import UpdateBlogs from "./dashboardComponent/UpdateBlogs.js";
import Intern from "./dashboardComponent/Intern.js";
import InternProfile from "./dashboardComponent/InternProfile.js";
import Batch from "./dashboardComponent/Batch.js";
import BatchDisc from "./dashboardComponent/BatchDisc.js";
import AddInBatch from "./dashboardComponent/AddInBatch.js";
import ClientTestimonial from "./dashboardComponent/ClientTestimonial.js";
import ErrorPage from "./Pages/ErrorPage.js";
import ErrorBoundary from "./Pages/ErrorBoundary.js";
import UpdateTestimonial from "./dashboardComponent/UpdateTestimonial.js";
import Notification from "./dashboardComponent/Notification.js";
import SessionSchedule from "./dashboardComponent/SessionSchedule.js";
import UpdateSession from "./dashboardComponent/UpdateSession.js";
import AdminLogin from "./dashboardComponent/AdminLogin.js";
import Users from "./dashboardComponent/Users.js";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/" element={<AdminDashboard />} />
      <Route path="/AdminLogin" element={<AdminLogin />} />
      <Route path="/AdminDashboard/Users" element={<Users/>}/>
      <Route path="/AdminDashboard/Batch" element={<Batch />} />
      <Route
        path="/AdminDashboard/BatchDisc/:batchid"
        element={<BatchDisc />}
      />
      <Route
        path="/AdminDashboard/AddInBatch/:batchid"
        element={<AddInBatch />}
      />
      <Route path="/AdminDashboard/Intern" element={<Intern />} />
      <Route
        path="/AdminDashboard/InternProfile/:profileId"
        element={<InternProfile />}
      />
      <Route
        path="/AdminDashboard/ClientTestimonial"
        element={<ClientTestimonial />}
      />
      <Route
        path="/AdminDashboard/UpdateTestimonial/:Id"
        element={<UpdateTestimonial />}
      />
      <Route path="/AdminDashboard/Blog" element={<Blog />} />
      <Route
        path="/AdminDashboard/UpdateBlogs/:blogId"
        element={<UpdateBlogs />}
      />
      <Route path="/AdminDashboard/SessionSchedule" element={<SessionSchedule/>} />
      <Route
        path="/AdminDashboard/UpdateSession/:sessionId"
        element={<UpdateSession/>}
      />
      <Route component={ErrorPage} />
      <Route path="/AdminDashboard/Notification" element={<Notification />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <RouterProvider router={router} />
    </ErrorBoundary>
  </React.StrictMode>
);
